import { NavMenus } from "../../Main/Main";
import style from "./NavbarMobile.module.scss";
import classNames from "classnames/bind";
import { useEffect, useState } from "react";

const cx = classNames.bind(style);

export default function NavbarMobile(props: {
    MenuScroll: (menu: string) => void;
    nowmenu: NavMenus;
}) {
    const [showMenu, setShowMenu] = useState(false);
    const [containerShow, setContainerShow] = useState(false);

    const menus = [
        { title: "Terra Poker" },
        { title: "Introduction" },
        { title: "Roadmap" },
        { title: "Community" },
    ];

    useEffect(() => {
        const body: any = document.querySelector("body");

        if (showMenu) {
            setContainerShow(true);
        } else if (!showMenu) {
            setTimeout(() => {
                setContainerShow(false);
            }, 400);
        }
    }, [showMenu]);

    useEffect(() => {
        function handleClickOutside(event: any) {
            const obj3 = document.getElementById("header_mobile_menu_wrapper");
            const obj4 = document.getElementById("header_mobile_button_menu");
            if (obj3 && obj4) {
                if (
                    !(obj3 as any).contains(event.target) &&
                    !(obj4 as any).contains(event.target)
                ) {
                    setShowMenu(false);
                }
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [setShowMenu]);

    return (
        <div className={style.navbar_mobile}>
            <div className={style.navbar_mobile_logo_wrap}>
                <div
                    className={style.navbar_mobile_logo}
                    onClick={() => props.MenuScroll("terrapoker")}
                />
            </div>

            <div className={style.litePaperWrapper}>
                <a
                    className={style.webappButton}
                    href={"https://terra.poker/"}
                    target="_blank"
                >
                    {"Webapp"}
                </a>
                <div className={style.verticalLine} />
                <a
                    className={style.litePaperIcon}
                    href="/litepaper.pdf"
                    target={"_blank"}
                    rel={"noreferrer"}
                />
                {/* <a
                    className={style.litePaper}
                    href="/litepaper.pdf"
                    target={"_blank"}
                    rel={"noreferrer"}
                >
                    {"Lite Paper"}
                </a> */}
            </div>
        </div>
    );
}
